import LogTaskAll from './LogTaskAll.js'

const LogSingle = ({index, ele}) => (
	<div className="list-single log" id={`listid-${ele.name}`}>
		<div className="list-single-headline">
			<div className="title">{ele.name}</div>
		</div>
		<LogTaskAll ele={ele}/>
	</div>
)

export default LogSingle