import _ from 'lodash'

import ProjectSingle from './ProjectSingle.js'

const ProjectAll = ({items}) => (
		<div className="lists projects-list">
			<div className="section-title">Projekter</div>
			{
				_.orderBy(items, [item => item.name.toLowerCase()], ['asc']).map((ele, i) => (
					<ProjectSingle key={ele.id} eleData={ele}/>
				))
			}
		</div>

	)

export default ProjectAll