import {useState} from 'react'
import {doc, deleteDoc, updateDoc} from 'firebase/firestore'
import {db} from './../firebase/firebase'

import starImg from './../../assets/images/star.png';


function LogTaskSingle({eleData}) {
	const [editTaskTitle, setEditTaskTitle] = useState(false)
	const [taskname, setTaskname] = useState()

	const deleteItem = async () => {
		if (window.confirm('Slet "'+eleData.data.name+'"?')) {
			const docRef = doc(db, `areas/${eleData.data.parentArea}/projects/${eleData.data.parentProject}/lists/${eleData.parentList}/tasks/${eleData.id}`)
			await deleteDoc(docRef)
		}
	}

	const updateItemName = () => {
		const docRef = doc(db, `areas/${eleData.data.parentArea}/projects/${eleData.data.parentProject}/lists/${eleData.parentList}/tasks/${eleData.id}`)
		updateDoc(docRef, {
			name: taskname
		})
		setEditTaskTitle(false)
	}

	const toggleStar = () => {
		const docRef = doc(db, `areas/${eleData.data.parentArea}/projects/${eleData.data.parentProject}/lists/${eleData.parentList}/tasks/${eleData.id}`)
		updateDoc(docRef, {
			isStared: !eleData.data.isStared
		})
	}

	return (
			<div className={`LogTaskSingle ${editTaskTitle ? 'editing' : ''}`}>
				{!editTaskTitle ? <div className="title" onClick={() => {setEditTaskTitle(true);setTaskname(eleData.data.name)}}>{eleData.data.name}</div> : null}
        		{editTaskTitle ? <input autoFocus type="text" className="edit-task-title" value={taskname} onChange={e => setTaskname(e.target.value)} onKeyPress={(e) => {if (e.key == 'Enter') updateItemName()}} onBlur={updateItemName}/> : false}
				<img src={starImg} className="star" style={{opacity: eleData.data.isStared ? '0.7' : '0.1'}} onClick={toggleStar}/>
				<div className="delete" onClick={deleteItem}>slet</div>
			</div>
		)
}

export default LogTaskSingle