import {useEffect, useState} from 'react';
import {db} from './../firebase/firebase'
import {collectionGroup, query, where, getDocs, collection, getDoc, doc} from 'firebase/firestore'
import {useMatch} from 'react-router-dom'
import _ from 'lodash'
import {Link} from 'react-router-dom'

import PageHeadline from './PageHeadline.js'

function StjerneListen(props) {
	const match = useMatch(':areaSlug/stjernelisten')
	const [tasks, setTasks] = useState([])

	useEffect(() => {
		const getParentListDoc = async (areaId, projectId, listId) => {
				const listRef = doc(db, `areas/${areaId}/projects/${projectId}/lists/${listId}`);
				const listDoc = await getDoc(listRef)

				const projectRef = doc(db, `areas/${areaId}/projects/${projectId}`);
				const projectDoc = await getDoc(projectRef)

				const returnData = {
					listName: listDoc.data().name,
					projectName: projectDoc.data().name,
					projectSlug: projectDoc.data().slug
				}
				
				return returnData
			}

		const fetchStaredTasks = async () => {
			let areaId;


			// get areaId
				const areaQ = query(collection(db, 'areas'), where('slug', '==', match.params.areaSlug))
				const querySnapshop = await getDocs(areaQ);
				querySnapshop.forEach(doc => {
					areaId = doc.id;
				})
			
			// fetch stared tasks
				const staredTasks = query(collectionGroup(db, 'tasks'), where('isStared', '==', true), where('parentArea', '==', areaId))
				const staredTasksSnapshot = await getDocs(staredTasks)
				let fields = []
/*
				console.log(staredTasksSnapshot);

				await Promise.all(staredTasksSnapshot.map(async docReturn => {
					const docRef = docReturn.ref
					const parentListId = docRef.parent.parent.id
					const parentProjectId = docRef.parent.parent.parent.parent.id

					// get name of parent list
						const returnVal = await getParentListDoc(areaId, parentProjectId, parentListId)
						console.log(returnVal.data().name);

					// set data
						let entryData = {
							id: docReturn.id, 
							name: docReturn.data().name,
							parentProjectId: docReturn.data().parentProject,
							// listName: returnVal.data().name
						}

						fields.push(entryData)

				}))
*/
				

				staredTasksSnapshot.forEach(docReturn => {
					const docRef = docReturn.ref
					// const parentListId = docRef.parent.parent.id
					// const parentProjectId = docRef.parent.parent.parent.parent.id

					// get name of parent list
						// const returnVal = await getParentListDoc(areaId, parentProjectId, parentListId)
						// console.log(returnVal.data().name);
						// const parentListRef = doc(db, `areas/${areaId}/projects/${parentProjectId}/lists/${parentListId}`);
						// const parentListRef = doc(db, `areas/${areaId}`);

						// const parentListDoc = getDoc(parentListRef)
						// console.log('areaid', areaId);

						// const parentProjectRef = doc(db, `areas/${areaId}/projects/${parentProjectId}`);
						// const parentProjectDoc = await getDoc(parentProjectRef)
						
						// console.log(parentListDoc.data(), parentProjectDoc.data());

					// get name of parent project

					// console.log('task:',docReturn.id, 'list:', docRef.parent.parent.id, 'project:', docRef.parent.parent.parent.parent.id);

					let entryData = {
						id: docReturn.id, 
						name: docReturn.data().name,
						parentListId: docRef.parent.parent.id,
						parentProjectId: docRef.parent.parent.parent.parent.id
						// listName: returnVal.data().name
					}

					// console.log(entryData);
					fields.push(entryData)
					// console.log(fields);
					// console.log(doc.data().parentProject);
				})

				await Promise.all(fields.map(async (singleField, i) => {
					const returnVal = await getParentListDoc(areaId, singleField.parentProjectId, singleField.parentListId)
					fields[i].listName = returnVal.listName
					fields[i].projectName = returnVal.projectName
					fields[i].projectSlug = returnVal.projectSlug
				}))
				
				let projectsArray = []
				let currentProjectArray;
				let currentProject;

				_.orderBy(fields, 'projectName').map(ele => {
					if (ele.projectName !== currentProject) {
						if (ele.name !== '') {
							if (currentProjectArray) projectsArray.push(currentProjectArray)
							currentProject = ele.projectName
							currentProjectArray = {
								name: ele.projectName,
								slug: ele.projectSlug,
								tasks: [ele]
							}
						}
					} else {
						if (ele.name !== '') currentProjectArray.tasks.push(ele)
					}
				})

				projectsArray.push(currentProjectArray)

				setTasks(projectsArray)

			// fetch all lists
				// const allLists = query(collectionGroup(db, 'lists'))
				// const allListsSnapshot = await(getDocs(allLists));
				// fields = []
				// allListsSnapshot.forEach(doc => {
				// 	console.log(doc.id, '=>', doc.data());
				// })

			// fetch all projects
				// const allLists = query(collectionGroup(db, 'projects'))
				// const allListsSnapshot = await(getDocs(allLists));
				// fields = []
				// allListsSnapshot.forEach(doc => {
				// 	console.log(doc.id, '=>', doc.data());
				// })
		}

		fetchStaredTasks();
	}, [])

	return (
		<div id="StjerneListen">
			<PageHeadline title="Stjernelisten"/>
			{tasks.map(projectEle => (
				<div className="list-single stjernelisten" key={projectEle.slug}>
					<div key={projectEle.slug} className="list-single-headline"><div className="title">{projectEle.name}</div></div>
					{projectEle.tasks.map(taskEle => (
						<Link to={`./../${taskEle.projectSlug}`} key={taskEle.id}>
							<div className="LogTaskSingle">
								<div className="listname">{taskEle.listName}</div>
								<div className="task">{taskEle.name}</div>
							</div>
						</Link>
						))}
				</div>
				))}
		</div>
		)
}

export default StjerneListen