import _ from 'lodash'

import ProjectSingleDetailsField from './ProjectSingleDetailsField.js'

const ProjectSingleDetails = ({data, areaId, projectId, listId}) => {
	return (
		<div className="ProjectSingleDetails">
			<div className="main">
				<ProjectSingleDetailsField colname="Status" editsize="small"  id={_.find(data, {'colname': 'status'}).id} name={_.find(data, {'colname': 'status'}).name} areaId={areaId} projectId={projectId} listId={listId}/>
				<ProjectSingleDetailsField colname="Next up" editsize="small"  id={_.find(data, {'colname': 'nextup'}).id} name={_.find(data, {'colname': 'nextup'}).name} areaId={areaId} projectId={projectId} listId={listId}/>
				<ProjectSingleDetailsField colname="Info" editsize="large" id={_.find(data, {'colname': 'info'}).id} name={_.find(data, {'colname': 'info'}).name} areaId={areaId} projectId={projectId} listId={listId}/>
				<ProjectSingleDetailsField colname="Kommentar" editsize="large" id={_.find(data, {'colname': 'comment'}).id} name={_.find(data, {'colname': 'comment'}).name} areaId={areaId} projectId={projectId} listId={listId}/>
				<ProjectSingleDetailsField colname="Evaluering" editsize="large" id={_.find(data, {'colname': 'evaluation'}).id} name={_.find(data, {'colname': 'evaluation'}).name} areaId={areaId} projectId={projectId} listId={listId}/>
			</div>
			<div className="sidebar">
				<div className="data">
					<ProjectSingleDetailsField colname="Formål" editsize="large" id={_.find(data, {'colname': 'purpose'}).id} name={_.find(data, {'colname': 'purpose'}).name} areaId={areaId} projectId={projectId} listId={listId}/>
				</div>
				<div className="data">
					<ProjectSingleDetailsField colname="Dato" editsize="small"  id={_.find(data, {'colname': 'date'}).id} name={_.find(data, {'colname': 'date'}).name} areaId={areaId} projectId={projectId} listId={listId}/>
					<ProjectSingleDetailsField colname="Ansvarlig" editsize="small"  id={_.find(data, {'colname': 'lmsresponsible'}).id} name={_.find(data, {'colname': 'lmsresponsible'}).name} areaId={areaId} projectId={projectId} listId={listId}/>
					<ProjectSingleDetailsField colname="Kontaktperson" editsize="small"  id={_.find(data, {'colname': 'contact_name'}).id} name={_.find(data, {'colname': 'contact_name'}).name} areaId={areaId} projectId={projectId} listId={listId}/>
					<ProjectSingleDetailsField colname="Kontaktinfo" editsize="small"  id={_.find(data, {'colname': 'contact_info'}).id} name={_.find(data, {'colname': 'contact_info'}).name} areaId={areaId} projectId={projectId} listId={listId}/>
				</div>
			</div>
        </div>
		)
}

export default ProjectSingleDetails