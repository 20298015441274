import {getAuth, signOut} from 'firebase/auth'
import {useSelector} from 'react-redux'
import {Link, useMatch} from 'react-router-dom';

import {doAdminStuff} from './adminStuff.js'


function NavBar(props) {
	const auth = getAuth();
	const userName = useSelector(state => state.user.name)
	let matchArea = useMatch(':areaSlug');
	let matchSubArea = useMatch(':areaSlug/:subAreaSlug');

	const logout = () => {
	    signOut(auth)
	  }

	 const FirstLetterToUpperCase = (str) => {
	 	const returnStr = str.charAt(0).toUpperCase() + str.slice(1);
	 	return returnStr
	 }

	// const LinkArea = () => props.currentpage.type == 'area' || props.currentpage.type == 'project' || props.currentpage.type == 'stars' ? <><NavLink exact to={'/'+props.currentpage.areaid}>{props.currentpage.areaname}</NavLink><NavLink exact to={'/'+props.currentpage.areaid+'/stjernelisten'}>Stjernelisten</NavLink></> : null
	const LinkArea = () => {
		return (
			matchArea ?
				matchArea.params.areaSlug ? 
				<>
					<Link to={`/${matchArea.params.areaSlug}`}>{FirstLetterToUpperCase(matchArea.params.areaSlug)}</Link>
					<Link to={`/${matchArea.params.areaSlug}/stjernelisten`}>Stjernelisten</Link>
				</>
					 : null
			: 

			matchSubArea ?
				matchSubArea.params.areaSlug ? 
				<>
					<Link to={`/${matchSubArea.params.areaSlug}`}>{FirstLetterToUpperCase(matchSubArea.params.areaSlug)}</Link>
					<Link to={`/${matchSubArea.params.areaSlug}/stjernelisten`}>Stjernelisten</Link>
				</> : null
			: null
		)
	}

	return (
		<div id="NavBar">
			<div id="BreadCrumb">
				<Link to="/">Forside</Link>
				<LinkArea/>
				
			</div>
			<div className="user">
				<div className="username">Hej {userName}</div>
				<div className="signout" onClick={logout}>Sign out</div>
{/*				<div className="signout" onClick={doAdminStuff}>admin</div>*/}
			</div>
		</div>
		)
}

export default NavBar