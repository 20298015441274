import {useState} from 'react'

import {updateDoc, doc} from 'firebase/firestore'
import {db} from './../firebase/firebase.js'

const ProjectSingleDetailsField = (props) => {
	const [taskname, setTaskname] = useState('');
	const [editTasktitle, setEditTasktitle] = useState(false);
	
	const updateTaskname = async () => {
		setEditTasktitle(false);

		const docRef = doc(db, `areas/${props.areaId}/projects/${props.projectId}/lists/${props.listId}/tasks/${props.id}`)
		await updateDoc(docRef, {
			name: taskname.replace(/\r?\n/g, '<br />')
		})
	}

	const activateEdit = () => {
		setEditTasktitle(true);
		setTaskname(props.name.replace(/<br\s*[\/]?>/gi, "\n"));
	}

	return (
		<div className={`ProjectDetailsSingleField ${props.colname.toLowerCase()}`}>
	    	<div className="colname">{props.colname}</div>
	    	{
	    		!editTasktitle ? <div className={`name ${!props.name || props.name === '' ? 'empty' : ''}`} onClick={activateEdit} dangerouslySetInnerHTML={{__html: props.name ? props.name : 'indtast her'}}/>
	    		: <textarea autoFocus className={`edit-task-title ${props.editsize}`} value={taskname} onChange={e => setTaskname(e.target.value)} onKeyPress={(e) => {if (e.key == 'Enter' && e.key == 'Enter' && !e.shiftKey) updateTaskname()}} onBlur={updateTaskname}/>
	    	}
	    </div>
		)

}
 export default ProjectSingleDetailsField