import _ from 'lodash'

import LogSingle from './LogSingle.js'

const LogAll = ({items}) => (
		<div className="lists">
			{
				_.orderBy(items, ['order'], ['asc']).map((ele, i) => (
					<LogSingle key={i} ele={ele}/>
				))
			}
		</div>

	)

export default LogAll