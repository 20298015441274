import {useState, useEffect} from 'react';
import {collection, addDoc, query, onSnapshot, orderBy} from 'firebase/firestore'
import {db} from './../firebase/firebase'

import LogTaskSingle from './LogTaskSingle.js'

function LogTaskAll({ele}) {
	const [input, setInput] = useState('');
	const [logs, setLogs] = useState([])

	useEffect(() => {
		const fetchLogs = async () => {
			const q = query(collection(db, `areas/${ele.areaId}/projects/${ele.projectId}/lists/${ele.id}/tasks`), orderBy('order'))
			const unsub = onSnapshot(q, snapshot => {
				const list = [];
				snapshot.forEach(logEle => {
					const logData = {
						id: logEle.id,
						data: logEle.data(),
						parentList: ele.id
					}
					list.push(logData)
				})
				setLogs(list);
			})
		}

		fetchLogs();
	}, [])

	const addItem = async (e) => {
		// set data
			const docData = {
				name: input,
				order: -1,
				isDone: false,
				isStared: false,
				parentArea: ele.areaId,
				parentProject: ele.projectId,
				parentList: ele.name
			}

		// add to db
			const colRef = collection(db, `areas/${ele.areaId}/projects/${ele.projectId}/lists/${ele.id}/tasks`)
			const docRef = await addDoc(colRef, docData)
			console.log(colRef);
			console.log(docRef.id);

		// reset
			e.target.blur();
			setInput('')
	}

	return (
		<div className="LogTaskAll">
			{logs.map(ele => (
				<LogTaskSingle eleData={ele} key={ele.id}/>
			))}
			<div className="addnew-wrapper">
	          <input placeholder="+ Tilføj log" className="addnew-input" value={input} type="text" onChange={e => setInput(e.target.value)} onKeyPress={e => {if (e.key == 'Enter') addItem(e)}}/>
	        </div>
		</div>
	)
}

export default LogTaskAll