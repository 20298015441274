import {configureStore} from '@reduxjs/toolkit'

import appNavigationReducer from './appNavigation'
import appUser from './appUser'

export default configureStore({
	reducer: {
		// appName: appNavigationReducer
		user: appUser
	}
})