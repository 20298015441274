import {createSlice} from '@reduxjs/toolkit'

export const appNavSlice = createSlice({
	name: 'appName',
	initialState: {
		currentpage: 'frontpage'
	},
	reducers: {
		setCurrentPage: (state, action) => {
			state.currentpage = action.name
		}
	}
})

export const {setCurrentPage} = appNavSlice.actions

export default appNavSlice.reducer