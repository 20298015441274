import {collectionGroup, query, getDocs, updateDoc} from 'firebase/firestore'
import{db} from './../firebase/firebase.js'

export const doAdminStuff = async () => {
		const q = query(collectionGroup(db, 'tasks'));
		const allTasks = await getDocs(q)

		allTasks.forEach(doc => {
			const projectId = doc.ref.parent.parent.parent.parent.id;
			const areaId = doc.ref.parent.parent.parent.parent.parent.parent.id;
			console.log(projectId, areaId);
			// console.log(doc.ref);
			updateDoc(doc.ref, {
				parentArea: areaId,
				parentProject: projectId
			})
			// console.log(doc.ref.parent.parent.parent.parent.id);

		})
	}