import {useEffect, useState} from 'react'
import {useMatch} from 'react-router-dom'
import {collection, onSnapshot, query, orderBy, getDocs, where, doc, deleteDoc, addDoc, writeBatch} from 'firebase/firestore'
import {db} from './../firebase/firebase'

import _ from 'lodash'

import PageHeadline from './PageHeadline.js'
import LogAll from './LogAll.js'
import ProjectAll from './ProjectAll.js'

function SubAreaSingle(props) {
	const [input, setInput] = useState('');
	const [projectName, setProjectName] = useState();
	const [projectId, setProjectId] = useState();
	const [projectLists, setProjectLists] = useState([]);
	const [areaId, setAreaId] = useState();

	let match = useMatch(':areaSlug/:subAreaSlug');


	useEffect(() => {
		let _areaId;
		let _projectId;

		const fetchProject = async () => {
			// get areaID
				const areaQ = query(collection(db, 'areas'), where('slug', '==', match.params.areaSlug))
				const areaQSnapshot = await getDocs(areaQ);
				areaQSnapshot.forEach(doc => {
					_areaId = doc.id;
					setAreaId(_areaId);
				})

			// get subareaID
				const projectsQ = query(collection(db, `areas/${_areaId}/projects`), where('slug', '==', match.params.subAreaSlug))
				const projectQSnapshot = await getDocs(projectsQ);
				projectQSnapshot.forEach(doc => {
					_projectId = doc.id;
					setProjectId(_projectId);
					setProjectName(doc.data().name)
				})

			// fetch projects
				const projectListsQ = query(collection(db, `areas/${_areaId}/projects/${_projectId}/lists`))
				const unsub = onSnapshot(projectListsQ, snapshot => {
				  	const list = []

				  	snapshot.forEach(ele => {
				  		let listItem = {
				  			id: ele.id,
				  			type: ele.data().type,
				  			name: ele.data().name,
				  			showTasks: ele.data().showTasks,
				  			areaId: _areaId,
				  			projectId: _projectId
				  		}
				    	list.push(listItem)
				  	})


				  	setProjectLists(list);
				});
		}

		fetchProject();

	}, [])

	const addProject = async () => {
		setInput('')

		// crate project

			const projectData = {
				name: input,
				showTasks: false,
				type: 'project'
			}
			const listsRef = collection(db, `areas/${areaId}/projects/${projectId}/lists`)
			const projectRef = await addDoc(listsRef, projectData)


		// set projectData
			let dbFields = [
				{colname: 'status', isStared: false},
				{colname: 'nextup', isStared: true}, 
				{colname: 'info', isStared: false},
				{colname: 'date', isStared: false},
				{colname: 'lmsresponsible', isStared: false},
				{colname: 'contact_name', isStared: false},
				{colname: 'contact_info', isStared: false},
				{colname: 'comment', isStared: false},
				{colname: 'evaluation', isStared: false},
				{colname: 'purpose', isStared: false}
			]

			const batch = writeBatch(db);

			dbFields.forEach((field) => {
				let fieldData = {
					colname: field.colname, 
					name: '', 
					isDone: false, 
					isStared: field.isStared, 
					parentArea: areaId, 
					parentProject: projectId, 
					parentList: field.colname === 'nextup' ? input+' - next up' : input
				}

				const docRef = doc(collection(db, `areas/${areaId}/projects/${projectId}/lists/${projectRef.id}/tasks`))
				batch.set(docRef, fieldData)
			})

			await batch.commit()

	}

	return (
		<div id="SubAreaSingle">
			<PageHeadline title={projectName}/>
			<div className="lists-wrapper">
				<LogAll items={_.filter(projectLists, {type: 'log'})}/>
				<ProjectAll items={_.filter(projectLists, {type: 'project'})}/>
				<div className="addnew-wrapper addnew-project-wrapper">
		          <input placeholder="+ Tilføj projekt" className="addnew-input" value={input} type="text" onChange={e => setInput(e.target.value)} onKeyPress={(e) => {if (e.key == 'Enter') addProject()}}/>
		        </div>
			</div>
		</div>
		)
}

export default SubAreaSingle