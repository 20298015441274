import {useEffect, useState} from 'react'
import {GoogleAuthProvider, getAuth, signInWithPopup, onAuthStateChanged, signOut} from 'firebase/auth'
import {doc, getDoc, setDoc} from 'firebase/firestore'

import {useSelector, useDispatch} from 'react-redux'
import {setUser} from './../store/appUser'

import {db} from './../firebase/firebase'

import App from './App.js'

function LoginPage(props) {
	const [currentUser, setCurrentUser] = useState();
	const [currentUserData, setCurrentUserData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	const googleProvider = new GoogleAuthProvider();
	const auth = getAuth();

	const dispatch = useDispatch();

	useEffect(() => {
		onAuthStateChanged(auth, async user => {
			if (user) {
				// user is signed in
					console.log('user is logged in', user);

				// get user from firestore
					const docRef = doc(db, 'users', user.uid)
					console.log(docRef);


					const docSnap = await getDoc(docRef)

					

					if (docSnap.exists()) {
						console.log('user exists in firestore');
						// user exists in firestore
							setCurrentUserData(docSnap.data());
					} else {
						// user does not exist in firestore
						console.log('user DOES NOT exists in firestore');
							// check if useremail is in the allowedlist
						  		const settingsRef = doc(db, 'settings', 'allowedUsers')
						  		const settingsSnap = await getDoc(settingsRef);
						  		console.log(settingsSnap.data());
						  		const listOfEmails = settingsSnap.data().email;
						  		const userCanEdit = listOfEmails.indexOf(user.email) > -1 ? true : false

							//  create user in firestore
								const userData = {
									canEdit: userCanEdit,
									displayName: user.displayName,
									email: user.email
								}
								const docRef = doc(db, 'users', user.uid);
								await setDoc(docRef, userData);
								const userSnap = await getDoc(docRef);
								setCurrentUserData(userSnap.data());
					}

				setCurrentUser(user)
				dispatch(setUser(user.displayName));
				setIsLoading(false)
			} else {
				// user is not logged in
					setCurrentUser();
					setIsLoading(false)
			}
		})
	}, [])

	const login = () => {
	    signInWithPopup(auth, googleProvider).then(result => {
	      const credential = GoogleAuthProvider.credentialFromResult(result)
	      const token = credential.accessToken
	      const user = result.user;
	    }).catch(error => {
	      console.log(error.message);
	    })
	  }

	  const logout = () => {
	    signOut(auth)
	  }

	return (
		isLoading ? 
			<div className="loginscreen">
				<div className="loading">Loading</div>
			</div>
		: currentUser ?
			<>
					{/*Hej {currentUser.displayName}<div className="loginBtn" onClick={logout}>LOGOUT</div> {/*move to topbar*/}
				{currentUserData.canEdit ? <App/> : <div className="loginscreen noaccess"><div>Beklager {currentUser.displayName}!<br/>Din googlekonto har ikke rettigheder nok</div><div className="logoutbtn btn" onClick={logout}>LOGOUT</div></div>}
			</>
		: 
			<div className="loginscreen">
				<div className="welcome">Velkommen</div>
				<div className="loginbtn btn" onClick={login}>
					LOGIN
				</div>
			</div>
	)
}

export default LoginPage