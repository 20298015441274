import {useEffect, useState} from 'react'
import {collection, onSnapshot, query, orderBy} from 'firebase/firestore'
import {db} from './../firebase/firebase'
import {Link} from 'react-router-dom'

function AreaList(props) {
  	const [areas, setAreas] = useState([]);

	useEffect(() => {
		const q = query(collection(db, 'areas'), orderBy('name'))
		const unsub = onSnapshot(q, snapshot => {
		  	const list = []
		  	snapshot.forEach(ele => {
		    	console.log(ele.id);
		    	list.push({
		    		id: ele.id,
		    		name: ele.data().name,
		    		slug: ele.data().slug
		    	})
		  	})
		  	setAreas(list);
		});
	}, [])

	return (
			<div className="AreaList">
				{areas.map((ele, i) => (
						<Link to={ele.slug} key={i} className="arealist-item-link">
							<div className="arealist-item">
								{ele.name}
							</div>
						</Link>
					))}
			</div>
		)
}

export default AreaList