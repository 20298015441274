import {createSlice} from '@reduxjs/toolkit'

export const appUser = createSlice({
	name: 'user',
	initialState: {
		name: null
	},
	reducers: {
		setUser: (state, action) => {
			state.name = action.payload
		}
	}
})

export const {setUser} = appUser.actions

export default appUser.reducer