import {useState, useEffect} from 'react'
import LogTaskAll from './LogTaskAll.js'

import {db} from './../firebase/firebase'
import {collection, query, onSnapshot, deleteDoc, doc, getDocs, writeBatch, updateDoc} from 'firebase/firestore'

import ProjectSingleDetails from './ProjectSingleDetails'

const ProjectSingle = ({eleData}) => {
	const [showDetails, setShowDetails] = useState(false);
	const [detailsData, setDetailsData] = useState();
	const [taskname, setTaskname] = useState()
	const [renameActive, setRenameActive] = useState(false)

	useEffect(() => {
		const q = query(collection(db, `areas/${eleData.areaId}/projects/${eleData.projectId}/lists/${eleData.id}/tasks`))
		const snapshot = onSnapshot(q, snap => {
			let details = []
			snap.forEach(ele => {
				const dataToPush = ele.data();
				dataToPush.id = ele.id
				dataToPush.areaId = eleData.areaId
				dataToPush.projectId = eleData.projectId
				dataToPush.listId = eleData.id
				details.push(dataToPush);
			})
			setDetailsData(details);
		})
	}, [])

	const deleteItem = async () => {
		if (window.confirm('Er du sikker på at du vil slette "'+eleData.name+'"?')) {
			// delete tasks
				const batch = writeBatch(db)
				const colRef = collection(db, `areas/${eleData.areaId}/projects/${eleData.projectId}/lists/${eleData.id}/tasks`);
				const docs = await getDocs(colRef)
				docs.forEach(snapShot => {
					const docRef = doc(db, `areas/${eleData.areaId}/projects/${eleData.projectId}/lists/${eleData.id}/tasks/${snapShot.id}`)
					batch.delete(docRef)
				})
				batch.commit();

			// delete doc
				const docRef = doc(db, `areas/${eleData.areaId}/projects/${eleData.projectId}/lists/${eleData.id}`)
				const response = await deleteDoc(docRef)
			
		}
	}

	const activateRenameItem = (e, ele) => {
		e.preventDefault();
		setTaskname(eleData.name)
		setRenameActive(true);
	}

	const updateItemName = () => {
		const docRef = doc(db, `areas/${eleData.areaId}/projects/${eleData.projectId}/lists/${eleData.id}`)
		updateDoc(docRef, {
			name: taskname
		})
		setTimeout(() => {
			setRenameActive(false)
		},100)
	}

	return (
		<div className={`list-single project ${showDetails ? 'active' : ''}`} id={`listid-${eleData.name}`}>
			<div className="list-single-headline">
				{!renameActive ? <div className="title" onClick={() => setShowDetails(!showDetails)}>{eleData.name}</div> : null}
				{renameActive ? <input autoFocus type="text" className="edit-task-title" value={taskname} onChange={e => setTaskname(e.target.value)} onKeyPress={(e) => {if (e.key == 'Enter') updateItemName()}} onBlur={updateItemName}/> : null}
				<div className="rename" onClick={activateRenameItem}>omdøb</div>
				<div className="delete" onClick={deleteItem}>slet</div>
			</div>
			{showDetails ? <ProjectSingleDetails data={detailsData} areaId={eleData.areaId} projectId={eleData.projectId} listId={eleData.id}/> : null}
		</div>
	)
}

export default ProjectSingle