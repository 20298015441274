import NavBar from './NavBar.js'
import {BrowserRouter, Routes, Route} from 'react-router-dom'

import AreaList from './AreaList.js'
import AreaSingle from './AreaSingle.js'
import SubAreaSingle from './SubAreaSingle.js'
import StjerneListen from './StjerneListen.js'

function App(props) {
	return (
		<div id="App">
			<BrowserRouter>
				<NavBar/>
				<Routes>
					<Route path='/' element={<AreaList/>}/>
					<Route path=':areaSlug' element={<AreaSingle/>}/>
					<Route path=':areaSlug/stjernelisten' element={<StjerneListen/>}/>
					<Route path=':areaSlug/:subAreaSlug' element={<SubAreaSingle/>}/>
				</Routes>
			</BrowserRouter>
		</div>
	)
}

export default App